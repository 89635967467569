exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-candidates-about-jsx": () => import("./../../../src/pages/candidates/about.jsx" /* webpackChunkName: "component---src-pages-candidates-about-jsx" */),
  "component---src-pages-candidates-blogs-jsx": () => import("./../../../src/pages/candidates/blogs.jsx" /* webpackChunkName: "component---src-pages-candidates-blogs-jsx" */),
  "component---src-pages-candidates-blogs-strapi-blog-slug-jsx": () => import("./../../../src/pages/candidates/blogs/{StrapiBlog.slug}.jsx" /* webpackChunkName: "component---src-pages-candidates-blogs-strapi-blog-slug-jsx" */),
  "component---src-pages-candidates-contact-jsx": () => import("./../../../src/pages/candidates/contact.jsx" /* webpackChunkName: "component---src-pages-candidates-contact-jsx" */),
  "component---src-pages-candidates-index-jsx": () => import("./../../../src/pages/candidates/index.jsx" /* webpackChunkName: "component---src-pages-candidates-index-jsx" */),
  "component---src-pages-candidates-jobs-jsx": () => import("./../../../src/pages/candidates/jobs.jsx" /* webpackChunkName: "component---src-pages-candidates-jobs-jsx" */),
  "component---src-pages-candidates-jobs-strapi-job-slug-jsx": () => import("./../../../src/pages/candidates/jobs/{StrapiJob.slug}.jsx" /* webpackChunkName: "component---src-pages-candidates-jobs-strapi-job-slug-jsx" */),
  "component---src-pages-candidates-services-jsx": () => import("./../../../src/pages/candidates/services.jsx" /* webpackChunkName: "component---src-pages-candidates-services-jsx" */),
  "component---src-pages-clients-about-jsx": () => import("./../../../src/pages/clients/about.jsx" /* webpackChunkName: "component---src-pages-clients-about-jsx" */),
  "component---src-pages-clients-blogs-jsx": () => import("./../../../src/pages/clients/blogs.jsx" /* webpackChunkName: "component---src-pages-clients-blogs-jsx" */),
  "component---src-pages-clients-blogs-strapi-blog-slug-jsx": () => import("./../../../src/pages/clients/blogs/{StrapiBlog.slug}.jsx" /* webpackChunkName: "component---src-pages-clients-blogs-strapi-blog-slug-jsx" */),
  "component---src-pages-clients-contact-jsx": () => import("./../../../src/pages/clients/contact.jsx" /* webpackChunkName: "component---src-pages-clients-contact-jsx" */),
  "component---src-pages-clients-index-jsx": () => import("./../../../src/pages/clients/index.jsx" /* webpackChunkName: "component---src-pages-clients-index-jsx" */),
  "component---src-pages-clients-jobs-jsx": () => import("./../../../src/pages/clients/jobs.jsx" /* webpackChunkName: "component---src-pages-clients-jobs-jsx" */),
  "component---src-pages-clients-jobs-strapi-job-slug-jsx": () => import("./../../../src/pages/clients/jobs/{StrapiJob.slug}.jsx" /* webpackChunkName: "component---src-pages-clients-jobs-strapi-job-slug-jsx" */),
  "component---src-pages-clients-services-jsx": () => import("./../../../src/pages/clients/services.jsx" /* webpackChunkName: "component---src-pages-clients-services-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

